<template>
  <div>
    <v-app-bar app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`" tile height="64" width="100%" class="document-viewpage" flat>
      <v-layout row wrap align-center class="ma-0 px-2 py-0">
        <v-flex xs1 sm1>
          <v-btn icon small dark @click="$parent.removeActiveModule">
            <v-icon size="20"> mdi-arrow-left </v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs7 sm9>
          <v-badge :content="total" inline :value="total">
            <span class="font-weight-bold px-1" :style="`color: ${ $vuetify.theme.dark ? '' : (systemDetails.textcolor || 'white') }`"> {{ $t('documents') }} </span>
          </v-badge>
        </v-flex>
        <v-flex xs4 sm2 class="px-0">
          <template v-if="payload.addClickHandler && accesscontrol.add && recordObj.accesscontrol.edit">
            <v-row>
              <v-col class="pa-0"  cols="12">
                <v-btn color="transparent"  fab x-small id="newbtn"
                  @click="openCreateTemplateDialog()" class="ml-2"  v-if="payload.addClickHandler" elevation="0" dark>
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
                <v-btn color="transparent" v-if="payload.uploadHanlder" fab x-small class="ml-2" id="relatebtn" @click="payload.uploadHanlder('upload')"
                  elevation="0" dark>
                  <v-icon> mdi-upload </v-icon>
                </v-btn>
                <v-btn color="transparent" v-if="payload.uploadHanlder" fab x-small class="ml-2" id="relatebtn" @click="createFolder"
                  elevation="0" dark>
                  <v-icon> mdi-folder-plus-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-flex>
      </v-layout>
      <template #extension v-if="breadCrumbItems && breadCrumbItems.length">
        <v-card width="100%" tile flat>
          <v-card-title :class="['pa-0 px-1', $vuetify.theme.dark ? '#1e1e1e' : 'grey lighten-3']">
            <v-layout row wrap align-center class="ma-0">
              <v-fade-transition>
                <v-breadcrumbs class="pa-2" :items="breadCrumbItems">
                  <template #item="{ item }">
                    <v-hover #default="{ hover }">
                      <v-breadcrumbs-item :class="getBreadCrumbItemIndex(item) !== (breadCrumbItems.length - 1) ? `pointer-hand ${ hover ? 'custom_breadcrumb_item_style_hover' : ''}` : ''"
                        @click="getBreadCrumbItemIndex(item) !== (breadCrumbItems.length - 1) ? navigateToItem(item) : null">
                        <span v-if="getBreadCrumbItemIndex(item) === (breadCrumbItems.length - 1)"
                          :class="['font-weight-bold grey--text', $vuetify.theme.dark ? 'text--lighten-2' : 'text--darken-1']">
                            {{ item.text }}
                        </span>
                        <span :class="['grey--text', $vuetify.theme.dark ? '' : 'text--darken-1']" v-else> {{ item.text }} </span>
                      </v-breadcrumbs-item>
                    </v-hover>
                  </template>
                  <template #divider>
                    <v-icon> mdi-forward </v-icon>
                  </template>
                </v-breadcrumbs>
              </v-fade-transition>
            </v-layout>
          </v-card-title>
        </v-card>
      </template>
    </v-app-bar>
    <v-container fluid :class="['px-2 py-3', $vuetify.theme.dark ? '' : 'grey lighten-3']" v-if="payload.loading">
      <v-row no-gutters>
        <v-col v-for="(item) in 11" :key="item" cols="12" sm="6" lg="3">
          <div>
            <v-skeleton-loader class="mx-auto mb-2" type="list-item"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card flat tile :color="$vuetify.theme.dark ? '' : '#f7f7f7'" v-else-if="payload.list && payload.list.length">
      <template>
        <v-row no-gutters class="mt-0 pl-2 pr-2">
          <v-col cols="12" sm="6" v-for="(document, docIndex) in payload.list" :key="docIndex" :class="$vuetify.breakpoint.smAndUp ? 'pl-2' : ''"
            @click="document.is_document ? navigateHandler(document) : loadChildrenItems(document)">
            <v-card outlined class="mb-1" :color="$vuetify.theme.dark ? '#1e1e1e' : '#fff'" >
              <v-layout row wrap align-center class="ma-0 px-3 py-3">
                <v-flex xs1>
                  <v-icon size="20" :color="$formatter.mimeTypeOfDocument(document.mimetype).color">
                    {{ $formatter.mimeTypeOfDocument(document.mimetype).icon }}
                  </v-icon>
                </v-flex>
                <v-flex xs10 class="px-4">
                  <template v-for="(prop, docPropIndex) in document.properties">
                    <span class="d-flex" :key="docPropIndex">
                      <template v-if="prop.name === 'FileLeafRef'">
                        <span style="max-width: 150px;" class="font-weight-medium subtitle-2 mb-n1 text-truncate"> {{ prop.value }} </span>
                        <template v-if="document.isvirtualdoc">
                          <v-icon size="20" class="pl-2" :key="docPropIndex" color="purple">mdi-check-circle</v-icon>
                          <span class="pl-1 font-weight-medium">{{ document.workflowstatus ? getStatusText(document.workflowstatus) : '' }}</span>
                        </template>
                      </template>
                    </span>
                    <span v-if="prop.name === 'Modified'" :key="`${docPropIndex}_modified`" class="caption">
                      <!-- {{ $formatter.fromUtcToLocal(prop.value, 'YYYY-MM-DDTHH:mm', 'DD.MM.YYYY HH:mm') }} -->
                      {{ $formatter.formatDate(prop.value, 'DD.MM.YYYYTHH.mm.ss', `${userDetails.dateformat} HH:mm`) }}
                    </span>
                  </template>
                </v-flex>
                <v-flex xs1 class="text-end">
                  <v-menu>
                    <template #activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" size="15"> mdi-dots-vertical</v-icon>
                    </template>
                    <v-card>
                      <v-list dense class="custom-dense-list pt-0 pb-0">
                        <template v-for="(action, index) in document.isvirtualdoc ? virtualDocActions : document.is_document ? defineActions(document) :  folderActions">
                          <v-list-item :key="index" @click="action.click(document)" v-if="action.isShow !== undefined ? action.isShow : true"
                             dense>
                            <v-list-item-title class="caption">
                              <v-icon :color="$vuetify.theme.dark ? '' : action.color" size="20" :class="action.icon === 'mdi-border-color' ? 'mt-1' : ''" class="subtitle-2 mb-3 mt-3 mr-2">
                                {{ action.icon }}
                              </v-icon>
                              {{ action.tooltip }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <v-alert prominent icon="mdi-information" text class="ma-2 mt-6 pa-5" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-else>
      <span class="font-weight-medium px-3"> {{ $t('noResults') }} </span>
    </v-alert>
     <!--Template Dialog -->
    <v-dialog v-model="templateDialog" width="500" persistent>
      <form-template :references.sync="templateFormReference" @closeDialog="closeTemplateDialogHandler();" :model="templateObj" ref="templateReference"></form-template>
    </v-dialog>
    <!--Upload or Create Dialog-->
    <v-dialog v-model="templateCreateDialog" fullscreen persistent>
      <v-card>
        <v-card-title :style="`background-color: ${systemDetails.themecolor}`" class="px-4 pt-3 pb-2">
          <span class="body-1 font-weight-bold" :style="{ color: systemDetails.textcolor }"> {{ $t('createTemplate') }} </span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="templateCreateDialog = false">
            <v-icon :color="systemDetails.textcolor"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-card flat tile max-height="83vh" class="overflow-y-auto">
            <span class="px-4 pt-3 pb-2 d-inline-flex subtitle-1 font-weight-bold"> {{ $t('sharepointFields') }} </span>
            <v-divider></v-divider>
            <form-template :references.sync="propertiesFormReference" :model="propertyModel.properties" ref="documentReference"></form-template>
            <span class="px-4 pt-3 pb-2 d-inline-flex subtitle-1 font-weight-bold"> {{ $t('templateFields') }} </span>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="12" lg="2" xs="12" sm="6" v-for="(field, index) in templateFieldsList.fields" :key="index">
                  <v-text-field v-model="field[field.placeholder]" outlined dense hide-details :label="$t(field.label)"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <template v-for="(module, index) in templateFieldsList.relationmodulefields">
                    <v-row :key="`relationshead_${index}`">
                      <v-col cols="6" class="pt-5 px-4">
                        <h3 :key="`relationshead_${index}`"> {{ module.module }} </h3>
                      </v-col>
                      <v-col cols="6" class="py-0">
                        <v-checkbox color="primary" hide-details label="Search by relation" v-model="module.includeRelation" @change="clearTemplateValues(index)"></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row :key="`relations_${index}`">
                      <v-col cols="12" lg="2" xs="12">
                        <!-- <v-autocomplete v-model="module.selected" outlined dense hide-details :ref="`searchRelatedInput_${module.module}_${index}`" :items="module.relatedRecords" item-text="name" item-value="_id" return-object
                          :label="$t('search')" @keyup="$event.keyCode !== 13 ? getRelatedRecords(`searchRelatedInput_${module.module}_${index}`, $event, module.module, index, module.includeRelation) : ''"
                          @change="setRelatedFields(index)" autocomplete="off"
                        ></v-autocomplete> -->
                        <v-autocomplete v-model="module.selected" outlined dense hide-details :ref="`searchRelatedInput_${module.module}_${index}`" :items="module.relatedRecords" item-text="data.name" item-value="_id" return-object
                          @keydown="preventSpecialCharacters($event)" :label="$t('search')" @keyup="$event.keyCode !== 13 ? getRelatedRecords(`searchRelatedInput_${module.module}_${index}`, $event, module.module, index, module.includeRelation) : ''" @change="setRelatedFields(index)"
                          autocomplete="off"
                        ></v-autocomplete>
                        </v-col>
                      <v-col cols="12" lg="2"  xs="12" v-for="(field, i) in module.fields" :key="`relationfield_${i}`">
                        <v-text-field v-model="field[field.placeholder]" outlined dense hide-details :label="$t(field.label)"></v-text-field>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
                <v-col cols="12" v-if="templateFieldsList.userfields && templateFieldsList.userfields.length > 0">
                  <v-row>
                    <v-col cols="12" class="pt-4">
                      <h3>{{ $t('user') }}</h3>
                    </v-col>
                    <v-col cols="2" lg="2" v-for="(field, i) in templateFieldsList.userFieldsArray" :key="`relationfield_user_${i}`">
                      <v-text-field v-model="field[field.model]"  outlined dense hide-details :label="$t(field.label)"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn color="success" class="mr-2" @click="savePlaceholderValues()" :loading="documentFormloading"> {{ $t('save') }} </v-btn>
          <v-btn color="error" @click="templateCreateDialog = false"> {{ $t('cancel') }} </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Upload or Create Dialog-->
    <v-dialog v-model="uploadDocumentDialog" width="500" persistent>
      <form-template :references.sync="propertiesFormReference" @closeDialog="closeDocumentDialogHandler()" :model="propertyModel.properties" ref="documentReference"></form-template>
    </v-dialog>
    <!--Version Dialog-->
    <v-dialog v-model="versionHistoryDialog" width="1000" persistent>
      <v-card class="pa-1">
        <versions :versionHistory="versionHistory"></versions>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="error" @click="versionHistoryDialog = false">{{$t('close')}}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Folder rename Dialog-->
    <v-dialog v-model="folderRenameDialog" width="550" persistent>
      <v-card>
        <v-card-title :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }; color: ${ $vuetify.theme.dark ? '' : (systemDetails.textcolor || '#fff') }`"
        class="px-4 py-3 body-1">
          {{ isCreateFolder ? $t('createFolder') : $t('rename') }}
          <v-spacer></v-spacer>
            <v-btn icon  @click="resetRenameModel" title="Close" :color="$vuetify.theme.dark ? '' : 'white'">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-form ref="renameForm" autocomplete="off">
            <v-text-field hide-details dense v-model="renameModel.name" outlined :label="$t('name')" :rules="$_requiredValidation" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="renameFolder" :loading="renameLoading" class="text-capitalize pa-4" small> {{ $t('ok') }} </v-btn>
          <v-btn color="error" @click="resetRenameModel" class="text-capitalize pa-4" small> {{ $t('close') }} </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Signature dialog-->
    <v-dialog v-model="showSignatureDialog" width="700" persistent>
      <v-card>
        <v-card-title class="pa-2">
         {{ $t('signMsg') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          <v-row class="mx-0">
            <v-col cols="12" lg="12" class="pa-1">
              <v-text-field dense hide-details :label="$t('subject')" v-model="signatureObj.subject" outlined></v-text-field>
            </v-col>
            <v-col cols="12" lg="12" class="pa-1">
              <v-textarea dense hide-details :label="$t('message')" v-model="signatureObj.message" outlined></v-textarea>
            </v-col>
            <v-col cols="12" lg="5" class="pa-1"><!-- class="text-end" -->
              <v-btn small color="primary text-capitalize" :loading="renameLoading" @click="signDocumentHandler" class="mr-4">
                <v-icon left dark> mdi-account-check </v-icon>{{ $t('signDocument') }}
              </v-btn>
            </v-col>
            <v-col cols="12" lg="7" class="pa-1">
              <v-btn small color="primary text-capitalize" :loading="renameLoading" @click="openRecipentDialog">
                <v-icon left dark> mdi-account-multiple </v-icon>{{ $t('shareForSign') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn small color="error" @click="closeSignatureDialog" >{{$t('close')}}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog to handle Recipents -->
    <v-dialog v-model="recipientDialog" width="650" persistent>
      <v-card>
        <v-card-title class="pa-2">
          {{ $t('addRecipents') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          <v-form ref="recipentForm">
            <v-simple-table dense width="100%" class="dense_table bordered--table mt-2 recipent-form-table">
              <thead>
                <tr>
                  <th>{{ $t('name') }} *</th>
                  <th>{{ $t('email') }} *</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(recipient, i) in listOfRecipients" :key="i">
                  <td width="45%">
                    <v-text-field dense hide-details v-model="recipient.name" :rules="$_requiredValidation"></v-text-field>
                  </td>
                  <td width="52%">
                    <v-text-field dense hide-details v-model="recipient.email" :rules="recipient.email ? $_emailValidation : $_requiredValidation"></v-text-field>
                  </td>
                  <td width="3%">
                    <v-btn color="secondary" text fab dark x-small class="mt-1 pb-0" @click="removeRecipients(i)"><v-icon dark>mdi-minus-circle</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-flex class="text-start">
              <v-btn color="success" x-small class="mt-2 mb-0 pb-0" fab @click="addRecipientItem"><v-icon>mdi-plus</v-icon></v-btn>
            </v-flex>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" @click="saveRecipientHandler" :loading="renameLoading" :disabled="listOfRecipients.length === 0">{{$t('send')}}</v-btn>
          <v-btn small color="error" @click="closeRecipientDialog">{{$t('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Envelope details-->
    <v-dialog v-model="envelopeDialog"  width="650" persistent>
      <div class="customtext">
      <v-card>
        <v-card-title class="pa-2"> {{ $t('details') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1">
          <v-row class="mx-0">
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold">{{ $t('subject') }} : </span> {{ envelopeObj.subject }}
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold">{{ $t('message') }} : </span> {{ envelopeObj.message }}
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold">{{ $t('status') }} : </span>
              <v-icon :color="getColor(envelopeObj.envelope_status)" x-small class="pr-2">mdi-checkbox-blank-circle</v-icon>
              <span id="text-style">{{ envelopeObj.envelope_status }}</span>
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              <span class="font-weight-bold"> {{ $t('recipients') }} </span>
              <v-list>
                <template v-for="(item, index) in envelopeObj.recipients">
                  <v-list-item :key="index" class="pa-0 envelope">
                    <v-list-item-action>{{index + 1}}</v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-medium">{{ item.email }}</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon :color="getColor(item.status)" x-small class="pr-2">mdi-checkbox-blank-circle</v-icon>
                        {{ item.status ?  `${ item.status[0].toUpperCase()}${item.status.slice(1)}` : '' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.declined_reason }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn small color="error" @click="envelopeDialog = false">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
      </div>
    </v-dialog>
    <!--Preview dialog for eml / msg file-->
    <v-dialog v-model="previewDialog" fullscreen persistent transition="dialog-bottom-transition" v-if="previewDialog">
        <v-toolbar dark color="primary">
          <v-btn icon dark  @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('previewFile') }}</v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-row>
            <table class="pl-5">
              <tr v-if="previewContent.from.email">
                <td><p class="black--text pt-4 mb-0" ><strong> {{ $t('from') }}: </strong>  </p></td>
                <td><p class="black--text pt-4 mb-0" >  {{ previewContent.from.email }}</p></td>
              </tr>
              <tr v-if="previewContent.date_on">
                <td><p class="black--text my-0"><strong> {{ $t('sentOn') }}: </strong> </p></td>
                <td><p class="black--text my-0"> {{ previewContent.date_on }}</p></td>
              </tr>
              <tr v-if="previewContent.to">
                <td><p class="black--text my-0"><strong> {{ $t('to') }}: </strong></p></td>
                <td><p class="black--text my-0">  {{previewContent.to}}</p></td>
              </tr>
              <tr v-if="previewContent.cc">
                <td><p class="black--text my-0"><strong> {{ $t('cc') }}: </strong>  </p></td>
                <td><p class="black--text my-0">  {{previewContent.cc}} </p></td>
              </tr>
              <tr v-if="previewContent.bcc">
                <td><p class="black--text my-0"><strong> {{ $t('bcc') }}: </strong> </p></td>
                <td><p class="black--text my-0"> {{previewContent.bcc}}</p></td>
              </tr>
              <tr v-if="previewContent.subject">
                <td><p class="black--text my-0" ><strong> {{ $t('subject') }}: </strong> </p></td>
                <td><p class="black--text my-0"> {{previewContent.subject}}</p></td>
              </tr>
              <tr v-if="previewContent.attachments">
                <td><p class="black--text my-0 pb-5"><strong> {{ $t('attachments') }}: </strong></p></td>
                <td><p class="black--text my-0 pb-5">{{previewContent.attachments}}</p></td>
              </tr>
            </table>
          </v-row>
          <v-card-text>
            <div v-html="previewContent.body"></div>
          </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Route Dialog -->
    <v-dialog v-model="routeDialog" fullscreen transition="dialog-bottom-transition" width="1500" v-if="routeDialog">
      <document-route :routeObj="routeObj" :recordName="name" v-if="routeDialog" :moduleObj="moduleObj" :key="reRenderRoute" :hasDocumentRedirectId="hasDocumentRedirectId"></document-route>
    </v-dialog>
    <!--Workflow Dialog-->
    <v-dialog v-model="workflowDialog" fullscreen transition="dialog-bottom-transition" width="1000" v-if="workflowDialog">
      <document-workflow :workflowObj="workflowObj"  v-if="workflowDialog"></document-workflow>
    </v-dialog>
    <!-- Workflow Detail -->
    <v-dialog width="1200" fullscreen v-model="workflowDetailsViewDialog">
      <workflow-detail v-if="workflowDetailsViewDialog" :workflowDetail="workflowDetail" :recordName="name"></workflow-detail>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import documentHelper from '@/components/documents/document_upload'
import { mapGetters } from 'vuex'
export default {
  props: ['sharepointId', 'name', 'recordObj', 'formattedObj'],
  mixins: [documentHelper],
  data () {
    return {
      dialog: false,
      isCreate: false,
      listOfTemplates: [],
      documentHeaders: [],
      listOfFiles: [],
      file: [],
      loading: false,
      // listOfFields: [],
      uploadDocumentDialog: false,
      moduleObj: {
        moduleId: this.$route.params.id,
        moduleName: this.$route.params.name,
        recordId: this.$route.params.record_id
      },
      propertyModel: {},
      formProps: [],
      listOfProperties: [],
      listOfDocuments: [],
      dynamicFormFields: [],
      dateFieldsForConversion: [],
      versionHistory: [],
      versionHistoryDialog: false,
      documentFormloading: false,
      /* pwa */
      payload: {
        addClickHandler: this.documentDialog,
        uploadHanlder: this.documentDialog,
        refreshHandler: this.getListOfDocuments,
        moduleTitle: 'Documents',
        loading: false,
        list: [],
        items: [],
        completeList: []
      },
      currentPage: 1,
      recordPerPage: 8,
      hasNextPage: true,
      accesscontrol: false,
      saveLoading: false,
      fieldsLoading: false,
      renameModel: {},
      folderRenameDialog: false,
      renameLoading: false,
      breadCrumbItems: [],
      removeFromIndex: 0,
      isCreateFolder: false,
      activeFolder: null,
      templateDialog: false,
      templateObj: {},
      templateFieldsList: [],
      templateCreateDialog: false,
      templateFileName: '',
      previewContent: {},
      previewDialog: false,
      signatureObj: {},
      showSignatureDialog: false,
      listOfRecipients: [],
      recipientDialog: false,
      envelopeDialog: false,
      envelopeObj: {},
      userFullDetails: {},
      // convertPdfAllowedTypes: ['jpg', 'png', 'jpeg', 'tiff', 'bmp', 'doc', 'docx', 'txt']
      convertPdfAllowedTypes: ['doc', 'docx', 'txt'],
      templateFieldLoading: false,
      reRenderRoute: 0,
      routeDialog: false,
      hasDocumentRedirectId: null,
      workflowDialog: false,
      workflowDetailsViewDialog: false,
      routeObj: {},
      total: 0
    }
  },
  components: {
    FormTemplate: () => import('@/components/FormTemplate'),
    versions: () => import('@/components/documents/Versions'),
    'document-route': () => import('@/components/documents/Router'),
    'document-workflow': () => import('@/components/documents/Workflow'),
    'workflow-detail': () => import('@/components/documents/WorkflowDetails')
  },
  watch: {
    '$store.state.common.sharepointFieldList' (val) {
      this.setHeadersAndProps()
    },
    '$store.state.common.listOfModules' (val) {
      this.setPermission()
    },
    'signatureObj.choiceMade' (val) {
      if (val === 2) {
        this.listOfRecipients = []
      }
    }
  },
  computed: {
    ...mapGetters(['formType', 'listOfFields', 'listOfModules', 'userDetails', 'getUsers']),
    actionItems () {
      return [{
        icon: '',
        click: ''
      }]
    },
    templateFormReference () {
      return {
        title: this.$t('chooseTemplate'),
        properties: [{
          model: 'template_id',
          type: this.formType.AUTO_COMPLETE,
          loading: this.templateFieldLoading,
          items: this.listOfTemplates,
          select_text: 'name',
          select_value: '_id',
          rules: this.$_multiSelectValidation,
          label: this.$t('selectTemplate'),
          class: 'lg12'
        }, {
          model: 'showalltemplates',
          type: this.formType.CHECKBOX,
          rules: [],
          click: this.getTemplates,
          label: this.$t('showAllTemplates'),
          class: 'xs12 lg2 mt-n3'
        }],
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.$t('continue'),
          click: this.getTemplateFields,
          loading: this.documentFormloading,
          is_show: true
        }, {
          name: 'close',
          color: 'error',
          click: this.closeTemplateDialogHandler,
          label: this.$t('close'),
          is_show: true
        }]
      }
    }
  },
  created () {
    this.setHeadersAndProps()
    this.getListOfDocuments(true)
  },
  mounted () {
    this.setPermission()
    if (this.$route.query && this.$route.query.docid) {
      this.hasDocumentRedirectId = this.$route.query.docid
    }
    const userFullDetails = this.getUsers.find(x => x._id === this.userDetails._id)
    this.userFullDetails = userFullDetails
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === 'sharepoint') {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
        this.$store.commit('hideDialog')
        this.getListOfDocuments()
      }
    })
    this.$eventBus.$on('refreshList', (data) => {
      this.getListOfDocuments(false)
    })
    this.$eventBus.$on('openRouteDialog', (data) => {
      this.routeObj.document = data
      this.getWorkFlows(data)
    })
    this.$eventBus.$on('closeRouteDialog', () => {
      this.routeDialog = false
    })
    this.$eventBus.$on('openWorkflowDialog', (data) => {
      this.workflowDialog = true
      this.workflowObj = data
    })
    this.$eventBus.$on('closeWorkflowDialog', (data) => {
      if (data) this.getListOfDocuments()
      this.hasDocumentRedirectId = null
      window.history.replaceState(null, null, window.location.pathname)
      this.workflowDialog = false
    })
    this.$eventBus.$on('closeWorkflowDetail', () => {
      this.workflowDetailsViewDialog = false
      this.hasDocumentRedirectId = null
      if (window.location) window.history.replaceState(null, null, window.location.pathname)
    })
    this.$eventBus.$on('reloadWorkflowDialog', (data) => {
      if (data) this.getWorkFlows(data)
    })
  },
  methods: {
    // getListOfDocuments () {
    //   this.loading = true
    //   this.payload.loading = true
    //   this.$api.execute('get', `documents/${this.moduleObj.moduleName}/get_documents/${this.moduleObj.recordId}`).then((response) => {
    //     this.listOfDocuments = response.data
    //     this.payload.list = response.data
    //     // this.payload.completeList = response.data
    //     // this.changePage(1)
    //     this.$api.execute('get', `documenttemplates/get_by_module/${this.moduleObj.moduleId}`).then(response => {
    //       this.listOfTemplates = response.data
    //     })
    //   }).finally(() => {
    //     this.loading = false
    //     this.payload.loading = false
    //   })
    // },
    getListOfDocuments (isForceLoad) {
      this.loading = true
      this.payload.loading = true
      if (!isForceLoad && this.breadCrumbItems.length > 1) {
        this.loadChildrenItems(this.breadCrumbItems[this.breadCrumbItems.length - 1], true)
        this.payload.loading = false
      } else {
        this.$api.execute('get', `documents/${this.moduleObj.moduleName}/get_all_folder_items/${this.moduleObj.recordId}`)
          .then(({ data }) => {
            this.getTemplates()
            const documents = data
            this.total = data.length
            documents.forEach(element => {
              if (!element.is_document) element.mimetype = 'folder'
            })
            this.payload.list = documents
            if (this.breadCrumbItems.length > 1) {
              this.breadCrumbItems.splice(1, this.breadCrumbItems.length)
              this.activeFolder = this.breadCrumbItems[0]
            } else {
              const breadcrumbItem = { id: this.sharepointId, text: this.name || '', isRoot: true, path: '' }
              this.activeFolder = breadcrumbItem
              const obj = this.breadCrumbItems.find(x => x.id === this.activeFolder.id)
              if (obj === null || obj === undefined) this.breadCrumbItems.push(breadcrumbItem)
            }
            if (this.hasDocumentRedirectId) {
              const result = documents.find(x => x.id === this.hasDocumentRedirectId)
              if (result && result.isvirtualdoc) this.getWorkflowDetails(result)
              else this.getWorkFlows(result)
            }
          }).finally(() => {
            this.loading = false
            this.payload.loading = false
          })
      }
    },
    getTemplates () {
      this.$api.execute('get', `documenttemplates/get_templates_for_creation/${this.moduleObj.moduleId}/${this.moduleObj.recordId}`).then(response => {
        this.listOfTemplates = response.data
      })
    },
    getRelatedRecords (term, event, moduleName, parentIndex, includeRelation) {
      if ([30, 32, 37, 38, 39, 40].indexOf(event.keyCode) > -1) return
      const searchText = this.$refs[term][0].lazySearch
      if (searchText !== null) {
        const model = {
          module: moduleName,
          searchterm: searchText,
          relation_module: this.$route.params.name,
          relation_id: this.$route.params.record_id
        }
        const url = includeRelation ? `moduledata/${moduleName}/search?searchterm=${model.searchterm}&relation_module=${model.relation_module}&relation_id=${model.relation_id}` : `moduledata/${moduleName}/search?searchterm=${model.searchterm}`
        this.$api.execute('get', url)
          .then(({ data }) => {
            if (data && data.length) {
              const obj = this.templateFieldsList.relationmodulefields[parentIndex]
              obj.relatedRecords = data
              this.$set(this.templateFieldsList.relationmodulefields, parentIndex, obj)
            }
          })
      }
    },
    setRelatedFields (parentIndex) {
      const selectedObj = this.$formatter.cloneVariable(this.templateFieldsList.relationmodulefields[parentIndex])
      if (selectedObj) {
        this.templateFieldsList.relationmodulefields[parentIndex].fields.forEach((element) => {
          if (element.module === selectedObj.module && !element.isSearch) {
            switch (element.type) {
              case 3: // Checkbox
                element[element.placeholder] = selectedObj.selected.data[element.name] ? this.$t('yes') : this.$t('no')
                break
              case 4: // Radio
                let defaultValue = element.default_value ? JSON.parse(element.default_value) : ''
                if (defaultValue && selectedObj.selected.data[element.name]) {
                  let optionValue = defaultValue.options.find(x => x.value === selectedObj.selected.data[element.name])
                  if (optionValue) {
                    element[element.placeholder] = optionValue.label
                  }
                }
                break
              case 5: // Select
                let selectedValue = selectedObj.selected[`field_${element.name}`]
                if (selectedObj.selected.data[element.name]) {
                  let selectDefaultValue = element.default_value ? JSON.parse(element.default_value) : ''
                  if (selectDefaultValue && selectDefaultValue.selectType === 'default') {
                    if (selectDefaultValue.is_multiselect) {
                      let result = selectDefaultValue.options.filter(x => selectedObj.selected.data[element.name].length && selectedObj.selected.data[element.name].includes(x.value))
                      element[element.placeholder] = result.map(e => e.label).join(',')
                    } else {
                      let singleSelectValue = selectDefaultValue.options.find(x => x.value === selectedObj.selected.data[element.name])
                      if (singleSelectValue) element[element.placeholder] = singleSelectValue.label
                    }
                  } else if (selectDefaultValue && selectDefaultValue.selectType === 'custom') {
                    element[element.placeholder] = selectedObj.selected.data[element.name]
                  } else if (selectedValue && selectedValue.length) {
                    let selectedValue = selectedObj.selected[`field_${element.name}`]
                    element[element.placeholder] = selectedValue.map(x => x.data.name).join(',')
                  }
                }
                break
              case 6: // DateTime
                element[element.placeholder] = (selectedObj.selected.data[element.name])
                  ? this.$formatter.formatDate(selectedObj.selected.data[element.name], '', this.userDetails.dateformat)
                  : ''
                break
              case 15: // user
                let selectedUsers = selectedObj.selected[`field_${element.name}`]
                if (selectedUsers && selectedUsers.length) {
                  element[element.placeholder] = selectedUsers.map(x => x.firstname || '' + x.lastname || '').join(',')
                }
                break
              case 16: // DateTime
                element[element.placeholder] = (selectedObj.selected.data[element.name])
                  ? this.$formatter.formatDate(selectedObj.selected.data[element.name], '', `${this.userDetails.dateformat} HH:mm`)
                  : ''
                break
              default:
                element[element.placeholder] = selectedObj.selected.data[element.name]
            }
          }
        })
      }
    },
    getTemplateFields () {
      if (this.$refs.templateReference && this.$refs.templateReference.$refs.validateForm.validate()) {
        this.documentFormloading = true
        this.$api.execute('get', `documents/${this.moduleObj.moduleName}/get_template_and_fields/${this.templateObj.template_id}`).then((response) => {
          this.templateFileName = response.data.docpath
          // response.data.fields.forEach(item => {
          //   item[item.placeholder] = this.recordObj.data[item.placeholder]
          // })
          response.data.fields.forEach(item => {
            if (item.placeholder === 'name') item[item.placeholder] = this.formattedObj.name
            else item[item.placeholder] = (this.formattedObj[item.name] && this.formattedObj[item.name].value) ? this.formattedObj[item.name].text : ''
            // item[item.placeholder] = this.formattedObj[item.placeholder].value ? this.formattedObj[item.placeholder].text : ''
            // item[item.placeholder] = this.recordObj.data[item.placeholder]
          })
          response.data.relationmodulefields.forEach(item => {
            item.relatedRecords = []
            item.selected = ''
            item.fields.forEach((field, index) => {
              field.module = item.module
              field[field.placeholder] = ''
            })
          })
          const userFieldsArray = []
          response.data.userfields.forEach(item => {
            let obj = {}
            switch (item) {
              case 'FirstName': obj = { label: 'firstName', name: 'FirstName', model: 'firstname', firstname: this.userFullDetails.firstname }
                break
              case 'LastName': obj = { label: 'lastName', name: 'LastName', model: 'lastname', lastname: this.userFullDetails.lastname }
                break
              case 'UserName': obj = { label: 'username', name: 'UserName', model: 'userName', username: this.userFullDetails.username }
                break
              case 'Email': obj = { label: 'email', model: 'email', name: 'Email', email: this.userFullDetails.email }
                break
              case 'Phone': obj = { label: 'mobile', model: 'phone', name: 'Phone', phone: this.userFullDetails.phone }
                break
            }
            userFieldsArray.push(obj)
          })
          response.data.userFieldsArray = userFieldsArray
          this.templateFieldsList = response.data
        }).finally(() => {
          this.documentFormloading = false
          this.$refs.templateReference.$refs.validateForm.reset()
          this.templateDialog = false
          this.templateCreateDialog = true
        })
      } else {
      }
    },
    savePlaceholderValues () {
      let templateValues = {}
      this.templateFieldsList.fields.forEach(item => {
        templateValues = { ...templateValues, [item.placeholder]: item[item.placeholder] }
      })
      this.templateFieldsList.relationmodulefields.forEach(element => {
        element.fields.forEach(item => {
          templateValues = { ...templateValues, [item.placeholder]: item.isSearch ? item[item.placeholder].name : item[item.placeholder] }
        })
      })
      if (this.templateFieldsList.userFieldsArray) {
        this.templateFieldsList.userFieldsArray.forEach(element => {
          templateValues = { ...templateValues, [`User:${element.name}`]: element[element.model] }
        })
      }
      const model = {
        parent_id: this.moduleObj.recordId,
        file_name: this.templateFileName,
        folder_id: this.activeFolder.id,
        properties: this.propertyModel.properties,
        template_values: templateValues
      }
      this.documentFormloading = true
      this.$api.execute('post', `documents/${this.moduleObj.moduleName}/create/${this.moduleObj.recordId}`, model).then((response) => {
        this.templateCreateDialog = false
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'createdSuccess' })
        this.getListOfDocuments()
      }).finally(() => {
        this.documentFormloading = false
      })
    },
    closeTemplateDialogHandler () {
      this.$refs.templateReference.$refs.validateForm.reset()
      this.templateDialog = false
      this.closeDocumentDialogHandler()
    },
    defineActions (document) {
      const text = document.name.split('.')
      if (text.length > 0) {
        const type = text[text.length - 1]
        let actions = []
        if (this.convertPdfAllowedTypes.includes(type)) {
          actions = [{
            tooltip: this.$t('convertToPdf'),
            icon: 'mdi-file-pdf',
            color: 'teal',
            // click: (documentObj) => this.convertToPdf(documentObj)
            click: (documentObj) => this.documentDialogConvertPDf('create', documentObj)
          }]
        }
        if (type === 'msg' || type === 'eml') {
          actions = [...actions, ...this.documentActions, ...this.documentsplActions]
          return actions
        } else if (this.userDetails.integrations && this.userDetails.integrations.docusign && (type === 'doc' || type === 'docx' || type === 'pdf' || type === 'xlsx')) {
          actions = [...actions, ...this.documentActions]
          if (this.accesscontrol) actions.splice(actions.length - 1, 0, this.docusignActions[0])
          else actions = [...actions, ...this.docusignActions]
          return actions
        } else {
          return [...actions, ...this.documentActions]
        }
      }
    },
    setHeadersAndProps () {
      if (this.listOfFields && this.listOfFields.length) {
        this.documentHeaders = []
        const listOfFields = this.$formatter.cloneVariable(this.listOfFields)
        const result = listOfFields.find(x => x.name === 'ContentType')
        if (!result) this.documentHeaders.push({ text: 'Type', value: 'ContentType' })
        listOfFields.forEach(element => {
          this.documentHeaders.push({ text: element.name !== 'ContentType' ? element.title : 'Type', value: element.name })
        })
        const avaliableProps = listOfFields || []
        this.dynamicFormFields = this.$formatter.cloneVariable(avaliableProps)
        avaliableProps.unshift({ title: 'Type' })
        this.listOfProperties = avaliableProps
        this.prepareFieldsForProperties()
      }
    },
    updateDocumentMeta () {
      this.documentFormloading = true
      const parentId = this.moduleObj.recordId
      this.propertyModel.parent_id = parentId
      let model = this.$formatter.cloneVariable(this.propertyModel.properties)
      model = Object.assign({ ...model }, { parent_id: parentId })
      // const transformData = { date: { list: this.dateFieldsForConversion, from: 'DD.MM.YYYY', to: 'MM.DD.YYYY' } }
      // model = this.$formatter.formatModel(model, transformData)
      const finalModel = {}
      this.dynamicFormFields.forEach(ele => {
        // eslint-disable-next-line
        if (!ele.readonly && model.hasOwnProperty(ele.name)) {
          finalModel[ele.name] = model[ele.name]
        }
      })
      this.$api.execute('put', `sharepoint/update_doc_meta_data/${this.propertyModel.id}`, finalModel)
        .finally(() => {
          this.documentFormloading = false
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
          this.closeDocumentDialogHandler()
          this.getListOfDocuments()
        })
    },
    resetDocumentHandler () {
      this.$refs.documentReference.$refs.validateForm.reset()
      this.uploadDocumentDialog=false
    },
    uploadDocumentHandler () {
      if (this.$refs.documentReference.$refs.validateForm.validate()) {
        this.documentFormloading = true
        const props = this.propertyModel.properties
        const parentId = this.moduleObj.recordId ? this.moduleObj.recordId : null
        // const transformData = { date: { list: this.dateFieldsForConversion, from: 'DD.MM.YYYY', to: 'MM.DD.YYYY' } }
        // const model = this.$formatter.formatModel(props, transformData)
        const model = props
        if (this.isCreate) {
          this.$api.execute('post', `documents/${this.moduleObj.moduleName}/create?parent_id=${parentId}&folder_id=${this.activeFolder.id}&template_id=${model.template_id}`, model).finally(() => {
            this.documentFormloading = false
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
            this.closeDocumentDialogHandler()
            this.getListOfDocuments()
          })
        } else {
          const formData = new FormData()
          formData.append('parent_id', this.activeFolder.id)
          const files = props.files
          formData.append(files.name, files)
          // files.forEach(file => {
          //   formData.append(file.name, file)
          // })
          for (const key in model) {
            if (key !== 'files') formData.append(key, model[key] || null)
          }
          this.$api.execute('post', 'sharepoint/upload', formData).finally(() => {
            this.documentFormloading = false
            this.payload.loading = false
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'uploadedSuccess' })
            this.closeDocumentDialogHandler()
            this.getListOfDocuments()
          })
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    openDialogHanlder (type) {
      if (type === 'create') this.isCreate = true
      this.dialog = true
    },
    closeDialogHandler () {
      this.file = []
      this.isCreate = false
      this.dialog = false
    },
    renameFolder () {
      if (this.$refs.renameForm.validate()) {
        this.renameLoading = true
        this.payload.loading = true
        const url = this.isCreateFolder ? 'sharepoint/create_folder' : 'sharepoint/rename'
        const method = this.isCreateFolder ? 'post' : 'put'
        let createFolderObj = {}
        if (this.isCreateFolder) {
          createFolderObj = { parent_id: this.moduleObj.recordId, module: this.moduleObj.moduleName, name: this.renameModel.name, so_id: this.activeFolder.id }
        }
        const model = this.$formatter.cloneVariable(this.isCreateFolder ? createFolderObj : this.renameModel)
        const text = this.isCreateFolder ? 'createdSuccess' : 'renameSuccess'
        this.$api.execute(method, url, model)
          .then((response) => {
            this.resetRenameModel()
            this.getListOfDocuments()
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: text })
          }).finally(() => {
            this.renameLoading = false
            this.payload.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    resetRenameModel () {
      this.renameModel = {}
      this.folderRenameDialog = false
      this.$refs.renameForm.reset()
    },
    loadChildrenItems (item, dontAdd) { // dont add item to breadcrumb if dontadd is true
      this.payload.loading = true
      this.activeFolder = this.$formatter.cloneVariable(item)
      const breadcrumbItem = { id: item.id, text: item.name, path: item.path }
      this.loading = true
      this.$api.execute('get', `documents/${this.moduleObj.moduleName}/get_subfolder_items/${item.id}`).then((response) => {
        const documents = response.data
        documents.forEach(element => {
          if (!element.is_document) element.mimetype = 'folder'
        })
        // add folder to breadcrumb
        if (this.removeFromIndex !== 0) {
          this.breadCrumbItems.splice(this.removeFromIndex, this.breadCrumbItems.length)
          this.removeFromIndex = 0
        }
        if (!dontAdd) this.breadCrumbItems.push(breadcrumbItem)
        this.payload.list = documents
      }).finally(() => {
        this.loading = false
        this.payload.loading = false
      })
    },
    navigateToItem (item) {
      this.removeFromIndex = this.getBreadCrumbItemIndex(item)
      if (item.isRoot) {
        this.getListOfDocuments(true) // do force load to not check conditon of breadcrumb active item
      } else {
        this.loadChildrenItems({ id: item.id, name: item.text })
      }
    },
    getBreadCrumbItemIndex (item) {
      return this.breadCrumbItems.indexOf(item)
    },
    createFolder () {
      this.isCreateFolder = true
      this.folderRenameDialog = true
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
        this.changePage(this.currentPage)
      }
    },
    nextPage () {
      if (this.currentPage < this.numPages()) {
        this.currentPage++
        this.changePage(this.currentPage)
      }
    },
    changePage (page) {
      this.currentPage = page
      this.payload.list = []
      if (page < 1) page = 1
      if (page > this.numPages()) page = this.numPages()
      if (this.payload.completeList.length > 0) {
        for (var i = (page - 1) * this.recordPerPage; i < (page * this.recordPerPage) && i < this.payload.completeList.length; i++) {
          this.payload.list.push(this.payload.completeList[i])
        }
      }
      if (page === 1) {
        this.hasNextPage = true
      }
      // eslint-disable-next-line
      if (page == this.numPages()) {
        this.hasNextPage = true
      } else {
        this.hasNextPage = false
      }
    },
    numPages () {
      return Math.ceil(this.payload.completeList.length / this.recordPerPage)
    },
    setPermission () {
      const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleObj.moduleId, this.userDetails)
      this.accesscontrol = hasManagePermission
    },
    signDocumentHandler () {
      const model = {
        doc_id: this.signatureObj.id,
        subject: this.signatureObj.subject,
        message: this.signatureObj.message,
        recipients: {}
      }
      this.renameLoading = true
      this.$api.execute('post', `docusign/sign_document/${this.signatureObj.id}`, model).then((response) => {
        if (!response.data.signed_already) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'sentSuccess' })
          window.open(response.data.url, '_self')
        } else {
          this.closeSignatureDialog()
          // this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'Document signed already' })
          this.envelopeObj = response.data
          this.envelopeDialog = true
        }
      }).finally(() => {
        this.renameLoading = false
      })
    },
    addRecipientItem () {
      this.listOfRecipients.push({})
    },
    removeRecipients (index) {
      this.listOfRecipients.splice(index, 1)
    },
    saveRecipientHandler () {
      if (this.$refs.recipentForm.validate()) {
        const recipientArray = this.$formatter.cloneVariable(this.listOfRecipients)
        var valueArr = recipientArray.map((item) => { return item.email })
        var containsDuplicate = valueArr.some((item, idx) => {
          // eslint-disable-next-line
          return valueArr.indexOf(item) != idx
        })
        if (containsDuplicate) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'differentEmail' })
        } else {
          let recipients = {}
          var model = {
            doc_id: this.signatureObj.id,
            subject: this.signatureObj.subject,
            message: this.signatureObj.message
          }
          recipientArray.forEach(recipent => {
            recipients = { ...recipients, [recipent.email]: recipent.name }
          })
          model.recipients = recipients
          this.renameLoading = true
          this.$api.execute('post', `docusign/share_document/${this.signatureObj.id}`, model).then(response => {
            if (!response.data.signed_already) {
              window.open(response.data.url, '_self')
            } else {
              // this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'document signed already' })
              this.envelopeObj = response.data
              this.envelopeDialog = true
            }
          }).finally(() => {
            this.renameLoading = false
            this.closeRecipientDialog()
            this.closeSignatureDialog()
          })
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    closeRecipientDialog () {
      this.listOfRecipients = []
      this.recipientDialog = false
    },
    closeSignatureDialog () {
      Object.keys(this.signatureObj).forEach(k => delete this.signatureObj[k])
      this.listOfRecipients = []
      this.showSignatureDialog = false
    },
    clearTemplateValues (index) {
      this.templateFieldsList.relationmodulefields[index].relatedRecords = []
      this.templateFieldsList.relationmodulefields[index].selected = null
      if (this.templateFieldsList.relationmodulefields[index].fields) {
        this.templateFieldsList.relationmodulefields[index].fields.forEach(item => {
          item[item.placeholder] = ''
        })
      }
    },
    getColor (status) {
      switch (status) {
        case 'sent': return 'blue'
        case 'delivered': return 'blue'
        case 'completed': return 'green'
        case 'correct': return '#ffce00'
        case 'declined': return 'red'
        case 'Voided': return 'grey'
      }
    },
    getStatusText (status) {
      let resultFound = this.listOfWorkflowStatus.find(x => x.id === status)
      if (resultFound) return resultFound.name
      else return ''
    },
    openRecipentDialog () {
      this.recipientDialog = true
    },
    openCreateTemplateDialog () {
      this.templateDialog = true
      this.getTemplates()
      this.payload.addClickHandler('create')
    },
    getTemplates () {
      this.templateFieldLoading = true
      this.$api.execute('get', `documenttemplates/get_templates_for_creation/${this.moduleObj.moduleId}/${this.moduleObj.recordId}?showall=${this.templateObj && this.templateObj.showalltemplates ? this.templateObj.showalltemplates : 'false'}`).then(response => {
        this.listOfTemplates = response.data
      }).finally(() => {
        this.templateFieldLoading = false
      })
    },
    getWorkFlows (document) {
      this.$api.execute('get', `documentworkflows/get_workflows/${document.id}`).then(response => {
        if (response.data && response.data.length > 0) {
          this.routeObj.showWorkflows = true
          this.routeObj.listOfWorkflows = response.data
        } else {
          this.routeObj.showWorkflows = false
          this.routeObj.listOfWorkflows = []
        }
        if (this.hasDocumentRedirectId) {
          let result = response.data.find(x => x._id === this.$route.query.workflowid)
          if (result) {
            this.workflowDetail = result
            this.workflowDetail.document = document
            this.workflowDetailsViewDialog = true
          }
        } else {
          this.routeObj.document = document
          this.routeDialog = true
          this.reRenderRoute++
        }
      })
    },
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('refreshList')
    this.$eventBus.$off('openRouteDialog')
    this.$eventBus.$off('closeRouteDialog')
    this.$eventBus.$off('openWorkflowDialog')
    this.$eventBus.$off('closeWorkflowDialog')
    this.$eventBus.$off('reloadWorkflowDialog')
    this.$eventBus.$off('closeWorkflowDetail')
  }
}
</script>
<style>
/* .documents_custom_scroll_style {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
} */
/* the below is added by vinoth from web */
.pointer-hand {
  cursor: pointer !important;
}
.custom_breadcrumb_item_style_hover {
  background: #e0e0e0;
}
.customtext .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
  color: unset !important;
}
#text-style {
  text-transform: capitalize !important;
}
.envelope .v-list-item__action {
  min-width: 0px !important;
}
/* the above is added by vinoth from web */
.recipent-form-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 8px !important;
}
.document-viewpage .v-toolbar__content {
  padding: 0 !important;
}
.document-viewpage .v-toolbar__extension {
  padding: 0 !important;
}
.custom-dense-list .v-list-item {
  min-height: 20px !important;
}
</style>
